<template>
  <div
    v-if="$parent.showCustom"
    class="notifyGroupWizardCustom"
  >
    <template>
      <div class="mb-3 alignCenter">
        <div class="cell">
          <font-awesome-icon
            class="mb-4 bigText"
            icon="user-tie"
          />
          <br>
          <div>
            <span>{{ `${$parent.roleRecipients.length} Recipients` }}</span>
            <font-awesome-icon
              class="ml-2"
              icon="external-link-square-alt"
              @click="$parent.openRecipientsModal()"
            />
          </div>
        </div>
      </div>
      <textarea
        ref="messageArea"
        v-model="$parent.message"
        :class="['form-control', { 'is-invalid': this.$validator.errors.has(`Message`) }]"
        placeholder="Please enter your custom message here."
        rows="5"
      />
      <span
        v-show="this.$validator.errors.has(`Message`)"
        class="badge badge-danger"
      >{{ this.$validator.errors.first(`Message`) }}</span>
      <div
        class="row mt-3"
      >
        <div class="col-12">
          <div class="cell">
            <h5>{{ 'Placeholders' }}</h5>
            <div class="row">
              <button
                class="btn btn-secondary"
                @click="addPlaceholder('{installation_name}')"
              >
                {{ $t('installationName') }}
              </button>
              <button
                class="btn btn-secondary ml-3"
                @click="addPlaceholder('{installation_url}')"
              >
                {{ 'Installation URL' }}
              </button>
              <button
                class="btn btn-secondary ml-3"
                @click="addPlaceholder('{current_date_time}')"
              >
                {{ $t('date') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: 'NotifyGroupWizardCustom',
  mixins: [
    errorMixin
  ],
  methods: {
     addPlaceholder (val) {
      if (this.$parent.message == null) {
          this.$parent.message = "";
        }
        var messageArea = this.$refs.messageArea;
        var messagePosition = messageArea.selectionStart
        var msg = this.$parent.message;
        this.$parent.message = msg.substring(0, messagePosition) + val + msg.substring(messagePosition);
        messageArea.focus();
    }
  }
}
</script>
